<template>
  <div class="speechapp">
    <div v-if="inited" class="speechbox">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import './constant'
// import store from '@/store/index'
import { mapGetters } from 'vuex'
// import { setUserAuth } from '@/scripts/userAuth'
import wxSDK from '@/scripts/wxsdk'
export default {
  data() {
    return {
      inited: false
    }
  },
  computed: {
    ...mapGetters({
      'userInfo': 'userAuth/getUsersCurrentInfo'
    }),
    showPage() {
      // const auth_type = global.APP_CONFIG.auth_type
      // const flag = (
      //   auth_type === 'customAuthLogin' ||
      //   ((auth_type === 'webAuthLogin' || auth_type === 'scanCodeAuthLogin') && this.userInfo))
      return true
    }
  },
  watch: {
    async '$route'(_to, _from) {
      if (!this.inited) {
        await this.init()
      }
    }
  },
  async created() {
    await this.init()
  },
  methods: {
    async init() {
      // console.log('created:init:getUsersCurrentInfo', store.getters['userAuth/getUsersCurrentInfo'])
      // if (!store.getters['userAuth/getUsersCurrentInfo']) {
      //   const params = { authType: 'webAuthLogin' }
      //   await setUserAuth(params)
      // }
      await wxSDK.config([], [
        'getContext',
        'sendChatMessage',
        'getCurExternalContact',
        'getCurExternalChat'
      ])
      this.inited = true
    }
  }
}
</script>

<style lang="less" scoped>
.speechapp {
  height: 100vh;
  .speechbox {
    height: calc(100vh - 114px);
    overflow: auto;
    margin-top: 64px;
  }
}
</style>
